<template>
  <!-- @mouseleave="onmouseout($event)" -->
  <div
    class="box"
    :style="$store.state.displayArea.rightWidth + $store.state.displayArea.height"
  >
    <div class="baidu-api" id="allmap" v-show="mapType == 'baidu'"></div>
    <div class="baidu-api" id="googlemap" v-show="mapType == 'Wgs84'"></div>
    <div class="search flex_align" v-if="isCustom">
      <div class="search_input flex_align">
        <el-autocomplete
          id="suggestId"
          style="width: 100%"
          @focus="focus"
          @blur="blur"
          v-model="search"
          :fetch-suggestions="querySearchAsync"
          :trigger-on-focus="false"
          :placeholder="$t('message.SearchLocation')"
          @select="handleSelect"
        >
          <template slot-scope="{ item }">
            <div class="flex_align search_title" style="width: 100%">
              <i class="el-icon-search fl mgr10" style="margin-right: 10px"></i>
              <div class="flex_align">
                {{ item.title }} <span v-if="item.address">({{ item.address }})</span>
              </div>
            </div>
          </template>
        </el-autocomplete>
        <div class="search_x mouse" @click="eliminate">×</div>
      </div>
      <div class="search_but mouse" @click="query()">
        <img src="@/assets/group/search.png" alt="" />
      </div>
    </div>
    <mapcustomfence
      ref="mapcustomfence"
      :markList="markList"
      :mapIconDisplay="mapIconDisplay"
      @openDrawing="openDrawing"
      @drawPolylines="drawPolylines"
      @drawCircle="drawCircle"
      @drawRectangle="drawRectangle"
      @previousStep="previousStep"
      @deleteFenceArea="deleteFenceArea"
      :baiduMap="map"
      @switchOnlineState="switchOnlineState"
      @changeMap="changeMap"
    >
    </mapcustomfence>
    <addGroup
      ref="addGroup"
      v-if="isBool"
      :dialogTitle="dialogTitle"
      @clearOverwrites="clearOverwrites"
      :efId="$store.state.efId"
      :overlays="overlays"
    ></addGroup>
    <upmarker
      ref="upmarker"
      :markerUser="markerUser"
      v-if="dialogModifyMarker"
      @successMarker="successMarker"
      @cloneMarker="cloneMarker"
    ></upmarker>
    <mapmark
      v-if="dialogModifyMark"
      @successMark="successMark"
      @cloneMark="cloneMark"
      :mark="mark"
      ref="mapmark"
    >
    </mapmark>
    <halfBall ref="halfball" @selectedBall="selectedBall"></halfBall>
    <msgTip
      :showcontent="msgTipData.showcontent"
      :tipstext="msgTipData.tipstext"
    ></msgTip>
    <div
      id="mouseOffset"
      v-if="editRegion != 1"
      :style="{
        position: 'absolute',
        'z-index': '9999999',
        top: offsetTop + 'px',
        left: offsetLeft + 'px',
        display: isShowMouse,
      }"
    >
      {{ $t("message.doubleclick")
      }}{{ editRegion == 3 ? $t("message.Create") : $t("message.Save") }}
    </div>
    <!-- 切换地图 -->
    <!-- <div class="map_type">
      <img v-if="mapType == 'Wgs84'" @click="cutMap('baidu')" src="@/assets/map/baidu.png" alt="">
      <img v-else @click="cutMap('Wgs84')" src="@/assets/map/google.png" alt="">
    </div> -->
  </div>
</template>
<script>
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import axios from "axios";
import qs from "qs";
import mapcustomfence from "@/components/mapcustomfence.vue";
import msgTip from "@/components/history/deletepop.vue";
import addGroup from "@/components/dispatchLntercom/addGroup.vue";
import upmarker from "@/components/map/upmarker.vue";
import mapmark from "@/components/map/mapmark.vue";
import halfBall from "@/components/map/halfBall.vue";
import beasconfig from "@/api/beasconfig.js";
import GeoUtils from "bmaplib.geoutils";
import { getQueryUserIcon } from "@/administration/upmarker.js";
import { markersos } from "./mixin/markersos";
import { markerusers } from "./mixin/markeruser";
import EventBus from "@/eventBus/eventBus.js";
import { _throttle } from "@/method/utils.js";
const gpsUrlapi = beasconfig.gpsUrlapi;
const regionUrlapi = beasconfig.regionUrlapi;
// console.log("gpsUrlapi", gpsUrlapi);
export default {
  name: "baiduMap",
  components: { mapcustomfence, msgTip, addGroup, upmarker, mapmark, halfBall },
  props: {},
  mixins: [markersos, markerusers],
  data() {
    return {
      mapIconDisplay: false,
      map: "",
      maps: "",
      type: 1,
      isShow: true,
      msgTipData: {
        showcontent: false, //是否显示
        tipstext: "", //文字提示
      },
      overlays: {},
      coordinate: [], //全部对讲用户
      distinguishType: 0,
      traffic: "", //路况
      theFirstTime: 0, //初次
      frequency: 0, //次数
      temporaryTime: null, //计时器
      timeNum: 0, //计时时间
      oneself: {
        lat: "",
        lng: "",
      },
      search: "",
      recordType: 0, //记录类型
      groupData: [],
      pointPosition: [],
      searchLocation: [],
      pointList: "", //经纬度数据
      isBool: false, //显示编辑弹出框
      editRegion: 1, //编辑区域
      efId: 0,
      dialogTitle: this.$t("message.Createregionalgroups"),
      offsetLeft: 0,
      offsetTop: 0,
      isShowMouse: "none",
      regionType: 0,
      userInfo: {}, //用户信息
      markerClusterer: null, //百度地图聚合
      isCustom: true,
      markers: [], //点聚合数组
      BmapType: BMAP_NORMAL_MAP,
      timeNum: 0, //判断谷歌地图是否加载完成
      mapType: "Wgs84", //切换百度谷歌
      gooleCover: [], //谷歌覆盖物数组
      userPoint: [], //定位点
      gooleWc: false, //谷歌地图是否加载完成
      mapTrafficLayer: null, //路况图层
      googleDraw: null, //谷歌绘制
      graphsDrawn: null, //绘制的图形
      googleDistanceTool: null, //谷歌地图测距功能
      rangeArr: [], //测距线条数组
      rangeCompleto: [], //测距完成的数组
      rangeCompletoArr: {}, //测距多个数组
      dialogModifyMarker: false,
      dialogModifyMark: false,
      recordMark: [],
      recordMarker: "", //百度地图Marker记录点击的定位点
      // temporaryTimeOne:null,   //30秒没有操作就调用地图刷新
      // timeNumOne:0
    };
  },
  created() {
    // this.clearTimer();
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  },
  // beforeMount: function() {

  // },
  beforeDestroy() {
    this.clearTimer();
    this.frequency = 0;
    this.theFirstTime = 0;
    this.$store.state.efRemark = "1";
    EventBus.$off("refreshMap");
    EventBus.$off("mapHoldall");
    EventBus.$off("position");
    EventBus.$off("changeMapOne");
    EventBus.$off("editEnclosureMap");
    EventBus.$off("emptyMap");
    EventBus.$off("mouseout");
    this.distanceTool.close();
    // 移除监听器，避免内存泄漏
  },
  mounted() {
    let that = this;
    that.baiduMap();
    that.initMap();
    setTimeout(() => {
      that.init();
    }, 500);
    window.closeLabel = this.closeLabel;
  },
  methods: {
    // 方法集合
    init() {
      let that = this;
      EventBus.$on("refreshMap", function () {
        //语音对讲
        that.startTime();
      });
      EventBus.$on("position", function (point) {
        //当前位置
        if (point) {
          that.map.setViewport([point]);
          that.maps.setCenter({ lat: Number(point.lat), lng: Number(point.lng) });
        } else {
          if (that.pointPosition.length > 0) {
            that.map.setViewport(that.pointPosition);
            that.setZoom(that.pointPosition, that.theFirstTime);
          } else {
            that.map.centerAndZoom("北京", 15);
            that.maps.setCenter({ lat: 38.903804230612835, lng: -77.0365677652717 });
          }
          that.search = "";
        }
        // that.removeMarkers();
      });
      EventBus.$on("mapHoldall", function (value) {
        //工具箱
        // console.log(value);
        if (value == 0) {
          //测距
          that.distanceTool.open();
        } else {
          that.drawingManager.open(); //开启绘制
          that.drawingManager.setDrawingMode(BMAP_DRAWING_MARKER);
        }
      });
      EventBus.$on("editEnclosureMap", function (row) {
        //编辑区域群组
        that.clearTimer();
        that.map.clearOverlays(); //清楚覆盖物
        that.theFirstTime = 0;
        that.$store.state.efId = row.regionId;
        that.overlays = {};
        if (row.regionId) {
          that.editRegion = 2;
          that.onmouseout(1);
          that.getRegionDetails();
          that.getDrawingManager();
        }
      });
      EventBus.$on("changeMapOne", function (row, type) {
        //选中名称
        // that.clearTimer();
        that.recordType = type;
        that.$store.state.selectedInfo = row == "" ? [] : [row];
        that.map.clearOverlays(); //清楚覆盖物
        that.theFirstTime = 0;
        that.$store.state.efId = 0; //每次点击清空
        that.editRegion = 1;
        if (row.regionId) {
          that.$store.state.efId = row.regionId;
        }
        that.map.ondblclick = null;
        that.onmouseout();
        if (that.$store.state.efId > 0 && row.children && row.children.length <= 0) {
          that.getRegionDetails();
        }
        that.getDrawingManager();
      });
      EventBus.$on("emptyMap", function (type) {
        //清除地图覆盖物
        if (type == 1) {
          //类型等于回退到最开始
          that.map.clearOverlays(); //清楚覆盖物
          that.editRegion = 1;
          that.emptyData();
        } else {
          that.pointPosition = [];
          that.$store.state.selectedInfo = [];
          that.$store.state.efId = 0;
          that.editRegion = type == 2 ? 1 : 3; //2是删除区域群组
          that.map.ondblclick = null;
        }
        that.theFirstTime = 0;
        //  if(that.editRegion != 3){
        that.onmouseout();
        //  }else{
        //   that.onmouseout(1);
        //  }
        that.clearOverwrites();
        that.getDrawingManager();
      });
      EventBus.$on("mouseout", function () {
        //清除地图覆盖物
        that.onmouseout();
      });
    },
    cutMap(type) {
      //切换地图
      if (this.mapType != type) {
        this.mapType = type;
        if (this.gooleWc == false) {
          //判断地图是否有加载出来
          this.initMap();
        }
        this.closeGraphical();
        this.cleanupDraw(); //先清除
        this.preventFrequentlyRefresh();
      }
    },
    googleMarkerLabel(mark) {
      //谷歌label
      if (mark) {
        const content = document.createElement("div");
        content.innerHTML = mark.html;
        const markerLabel = new google.maps.marker.AdvancedMarkerElement({
          map: this.maps,
          content: content,
          position: mark.position,
          // title: '',
        });
        return markerLabel;
      }
    },
    //谷歌地图
    initMap() {
      if (window.googleMap) {
        //window.googleMap有值就代表地图API已经加载完成
        this.timeNum = 0;
        try {
          this.maps = new google.maps.Map(document.getElementById("googlemap"), {
            zoom: 10,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            //地图中心点，这里我以第一个数据的经纬度来设置中心点
            center: { lat: 38.903804230612835, lng: -77.0365677652717 },
            disableDefaultUI: false,
            zoomControl: false,
            maxZoom: 17, //18
            minZoom: 3,
            disableDoubleClickZoom: true,
            mapId: "WURUI_MAP_ID",
            draggableCursor: "auto",
          });
          this.mapTrafficLayer = new google.maps.TrafficLayer();
          const renderer = {
            //设置自定义图标
            render: ({ count, position }) =>
              new google.maps.Marker({
                label: { text: String(count), color: "#000000", fontSize: "12px" },
                position,
                icon: {
                  //设置集群的图片
                  url: this.roundPng(count),
                  // scaledSize: new google.maps.Size(65, 32),
                },
                // 设置zIndex
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
              }),
          };
          this.distanceMarkers = [];
          this.googleMarkerClusterer = new MarkerClusterer({
            map: this.maps,
            markers: [],
            renderer,
          }); //聚合
          // 创建测距工具
          // this.googleDistanceTool = new MeasureTool(this.maps,{
          //     contextMenu:false,
          //     showSegmentLength: false,
          //     tooltip: false,
          //     unit: MeasureTool.UnitTypeId.METRIC // metric, imperial, or nautical
          // });
          // 保存测量的路径
          var time = null;
          // 创建一个标签来显示总距离
          // 监听地图的点击事件，添加测量点
          google.maps.event.addListener(this.maps, "click", (event) => {
            // console.log(path.getArray())
            if (this.distinguishType == 5) {
              var path = this.googleDistanceTool.getPath();
              time = setTimeout(() => {
                //点击事件跟双击事件有冲突所以需要给个延迟
                path.push(event.latLng); // 添加点到路径中
                this.rangeArr.push(event.latLng);
                //直接调用API接口来计算几条线段的长度和 单位米
                let arr = path.getArray();
                var distanceValue = google.maps.geometry.spherical.computeLength(arr);
                distanceValue = `<div class="ranging">${(distanceValue / 1000).toFixed(
                  2
                )}Km</div>`;
                var starthtml = `<div class="ranging">${this.$t(
                  "message.startingpoint"
                )}</div>`;
                let rounddot = this.googleMarkerLabel({
                  position: arr[arr.length - 1],
                  html: `<div class='cj_circular mouse_dw'></div>`,
                }); //圆形
                let start = this.googleMarkerLabel({
                  position: arr[arr.length - 1],
                  html: path.getArray().length > 1 ? distanceValue : starthtml,
                }); //圆形
                this.rangeCompleto.push(rounddot, start);
              }, 200);
            }
          });
          // 添加双击事件监听器
          google.maps.event.addListener(this.maps, "dblclick", () => {
            clearTimeout(time);
            // 获取双击事件的经纬度
            // const latLng = event.latLng;
            // // 在控制台输出双击事件的经纬度
            // console.log("Double click at: " + latLng.lat() + ", " + latLng.lng());
            // this.googleDistanceTool.end();
            if (this.distinguishType == 5) {
              this.rangingTotalLength();
            }
          });
          // 监听鼠标右键点击事件
          google.maps.event.addListener(this.maps, "rightclick", () => {
            // 获取点击位置的经纬度
            if (this.distinguishType == 5) {
              if (this.rangeArr.length > 1) {
                this.rangingTotalLength();
              } else {
                this.cleanupDraw(); //先清除
              }
            }
          });
          this.gooleWc = true;
        } catch (error) {
          this.gooleWc = false;
          // console.log("需要翻墙")
        }
      } else {
        setTimeout(() => {
          this.timeNum += 1;
          if (this.timeNum <= 10) {
            this.initMap();
          }
        }, 1000);
      }
    },
    rangingTotalLength() {
      //测距保存
      if (this.rangeArr.length > 0) {
        let marker = this.rangeCompleto[this.rangeCompleto.length - 1]; //把最后一个添加的给删除
        this.rangeCompleto.splice(this.rangeCompleto.length - 1, 1); //删除最后一个重新添加
        // let leng = this.rangeCompletoArr.length + 1;
        marker.setMap(null);
        var path = this.googleDistanceTool.getPath();
        let arr = path.getArray();
        var distanceValue = google.maps.geometry.spherical.computeLength(arr);
        distanceValue = `<div class="ranging_total">${this.$t("message.totallength")}：${(
          distanceValue / 1000
        ).toFixed(2)}Km</div>`;
        let end = this.googleMarkerLabel({
          position: arr[arr.length - 1],
          html: distanceValue,
        });
        var pint = this.$moment(new Date()).valueOf();
        let close = this.googleMarkerLabel({
          position: arr[arr.length - 1],
          html: `<div class="close_range" onclick="closeLabel(${pint},2,event)" >×</div>`,
        });
        this.rangeCompleto.push(end, close); //保存当前的数组
        var line = new google.maps.Polyline({
          path: this.rangeArr,
          strokeColor: "#FF0000", // 线条颜色
          strokeOpacity: 1.0, // 线条透明度
          strokeWeight: 2, // 线条宽度
          map: this.maps, // 绑定地图实例
        });
      }
      this.rangeCompletoArr[pint] = {
        line: line,
        marker: this.rangeCompleto,
      };
      this.googleDistanceTool.setMap(null);
      this.rangeArr = []; //绘制的线
      this.rangeCompleto = [];
      this.distinguishType = 0;
      this.maps.setOptions({ draggableCursor: `auto` }); //设置谷歌地图鼠标图标
    },
    // 百度地图
    onmouseout(type) {
      let that = this;
      if (that.distinguishType > 0) {
        //离开百度地图并且在绘制多边形的时候刷新地图
        if (that.distinguishType == 1) {
          that.cancellationMap();
          that.baiduMap();
        }
        that.drawingManager.close(); //停止绘制
        that.overlays = {};
        that.isShowMouse = "none";
        that.distinguishType = 0;
        that.$refs.mapcustomfence.selectState.isCustom = false;
        that.isCustom = true;
        if (type != 1) {
          //不等于的话需要刷新
          setTimeout(() => {
            that.switchOnlineState(that.type);
          }, 300);
        }
      } else {
        // if(type != 1){
        that.switchOnlineState(that.type);
        // }
      }
    },
    cancellationMap() {
      //注销地图
      let that = this;
      try {
        // 注销地图（换成你初始化时定义的地图变量）
        that.map = null;

        // 获取interval的最高ID然后遍历清除
        const HIGHEST_INTERVAL_ID = setInterval(";");
        for (let i = 0; i < HIGHEST_INTERVAL_ID; i++) {
          clearInterval(i);
        }

        // 获取百度地图创建的DOM并销毁
        const BAIDU_MAPS = document.querySelectorAll(".baidu-api");
        BAIDU_MAPS.forEach((item) => {
          document.body.removeChild(item);
        });
      } catch (e) {
        // console.log(e);
      }
    },
    getRegionDetails() {
      axios
        .get(
          `${regionUrlapi}-${this.$store.state.userState.server}/region/${this.$store.state.efId}`
        )
        .then((res) => {
          if (res.data.code == 200) {
            // console.log(res)
            this.regionType = res.data.data.regionType;
            this.$store.state.efRemark = res.data.data.regionType;
            this.$store.state.efId = res.data.data.regionId;
            this.pointList = res.data.data.pointList;
            this.clearOverwrites(1);
            /*画围栏*/
            this.drawFence();
          } else {
            this.$MessageWarning(res.data.message);
          }
        });
    },
    /*画围栏*/
    async drawFence() {
      var that = this;
      var list = [];
      let color;
      let fillColor;
      if (that.editRegion == 1) {
        color = "#0064fc";
        fillColor = "rgba(22,111,245,0.2)";
      } else {
        color = "#ff3c00";
        fillColor = "rgba(255,60,0,0.3)";
      }
      if (that.$store.state.efRemark == "2") {
        var pointLists = that.pointList.split(";");
        var pointLists1 = pointLists.join("|").split("|");
        await pointLists1.map((item) => {
          var pt = item.split(",");
          var pts =
            that.mapType == "Wgs84"
              ? { lat: Number(pt[0]), lng: Number(pt[1]) }
              : new BMap.Point(pt[0], pt[1]);
          list.push(pts);
        });

        for (var i = 0; i < pointLists.length; i++) {
          var boundaries = pointLists[i].replace(/\|/g, ";");
          var polygon = new BMap.Polygon(boundaries, {
            strokeStyle: "dashed",
            strokeWeight: 2,
            strokeColor: color,
            fillColor: fillColor,
            strokeOpacity: 1,
          });
          polygon.id = 5;
          that.map.addOverlay(polygon); //添加覆盖物
        }
        // const triangleCoords = [
        //     { lat: 22.58251886037166, lng: 113.95029316140746 },
        //     { lat: 22.582380173101985, lng: 113.947868444458 },
        //     { lat: 22.580785259466033, lng: 113.94823322488402 },
        //     { lat: 22.58084469757252, lng: 113.94975671960448 },
        //   ];
        // const bermudaTriangle = new google.maps.Polygon({
        //         map:that.maps,
        //         paths: triangleCoords,
        //         strokeColor:"#FF0000",
        //         strokeOpacity: 0.8,
        //         strokeWeight: 2,
        //         fillColor:"#FF0000",
        //         fillOpacity: 0.35,
        //         editable:true
        //   });
        //  google.maps.event.addListener(bermudaTriangle.getPath(), 'insert_at', function(evt) {   //监听多边形是否修改了数据
        //     console.log(evt)
        //     // let arr = bermudaTriangle.getPath();
        //     console.log(bermudaTriangle.getPath())
        //     console.log(bermudaTriangle.getPath().getAt(evt))
        //     console.log(bermudaTriangle.getPath().getAt(evt).toUrlValue(6))
        //   });
      } else {
        var pointLists = that.pointList.split("|");
        await pointLists.map((item) => {
          var pt = item.split(",");
          var pts =
            that.mapType == "Wgs84"
              ? { lat: Number(pt[1]), lng: Number(pt[0]) }
              : new BMap.Point(pt[0], pt[1]);
          console.log(pts);
          list.push(pts);
        });
        if (that.mapType == "Wgs84") {
          // console.log(list)
          const bermudaTriangle = new google.maps.Polygon({
            map: that.maps,
            paths: list,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            editable: true,
          });
          google.maps.event.addListener(
            bermudaTriangle.getPath(),
            "insert_at",
            function (evt) {
              //监听多边形是否修改了数据
              console.log(evt);
              // let arr = bermudaTriangle.getPath();
              console.log(bermudaTriangle.getPath());
              console.log(bermudaTriangle.getPath().getAt(evt));
              console.log(bermudaTriangle.getPath().getAt(evt).toUrlValue(6));
            }
          );
          google.maps.event.addListener(
            bermudaTriangle.getPath(),
            "remove_at",
            function (evt) {
              //监听多边形是否修改了数据
              console.log(evt);
              // let arr = bermudaTriangle.getPath();
              console.log(bermudaTriangle.getPath());
            }
          );

          this.graphsDrawn = bermudaTriangle;
        } else {
          var polygon = new BMap.Polygon(list, {
            strokeStyle: "dashed",
            strokeWeight: 2,
            strokeColor: color,
            fillColor: fillColor,
            strokeOpacity: 1,
          });
          polygon.id = 5;
          that.map.addOverlay(polygon);
          polygon.addEventListener("lineupdate", (e) => {
            // console.log("e", e);
            that.overlays = e.target;
          });
          that.polygon = polygon;
        }
      }

      if (that.editRegion == 2) {
        that.locationData();
      }
      // this.map.setViewport(polygon.getPath());  //调整视野
      if (that.editRegion == 1) {
        let array = that.pointPosition;
        array.forEach((item1) => {
          if (item1) {
            var pts = new BMap.Point(item1.lng, item1.lat);
            list.push(pts);
          }
        });
      }
      setTimeout(function () {
        /*整体自适应居中*/
        that.setZoom(list, that.theFirstTime);
      }, 300);
    },
    /*区域编辑*/
    locationData() {
      this.hintDivShow = true;
      if (this.regionType == "1") {
        this.polygon.enableEditing(); //开启编辑
      }
      this.mapOndblclick();
    },
    eliminate() {
      //清除
      this.search = "";
      this.searchLocation = [];
    },
    querySearchAsync(queryString, cb) {
      let that = this;
      if (queryString != "" && queryString != null) {
        if (that.mapType == "Wgs84") {
          // 创建 AutocompleteService 对象
          var autocompleteService = new google.maps.places.AutocompleteService();
          // 创建 Autocomplete 对象
          autocompleteService.getPlacePredictions(
            { input: queryString },
            function (predictions, status) {
              let arr = [];
              if (status === google.maps.places.PlacesServiceStatus.OK) {
                predictions.forEach((item) => (item.title = item.description));
                arr = predictions;
                cb(arr);
              } else {
                cb(arr);
              }
            }
          );
        } else {
          var options = {
            onSearchComplete: function (res) {
              //检索完成后的回调函数
              // console.log(res)
              var s = [];
              if (local.getStatus() == BMAP_STATUS_SUCCESS) {
                for (var i = 0; i < res.getCurrentNumPois(); i++) {
                  s.push(res.getPoi(i));
                }
                cb(s);
              } else {
                cb(s);
              }
            },
          };
          var local = new BMap.LocalSearch(that.map, options); //创建LocalSearch构造函数
          local.search(queryString); //调用search方法，根据检索词str发起检索
        }
      }
    },
    query() {
      if (this.search != "" && this.search != null) {
        if (this.mapType == "Wgs84") {
          this.maps.setCenter(this.searchLocation[0]);
        } else {
          if (this.editRegion == 1) {
            this.map.setViewport(this.searchLocation);
          } else {
            this.map.clearOverlays();
            this.$store.state.efRemark = "2";
            this.getAdministrativeArea({ title: this.search });
            this.mapOndblclick();
          }
        }
      } else {
        this.searchLocation = [];
      }
      EventBus.$emit("emptyChoice");
    },
    handleSelect(item) {
      let that = this;
      that.search = item.title;
      if (that.mapType == "Wgs84") {
        // that.searchLocation = [item.point]; //搜索选中的定位信息
        // 使用 Geocoder 获取地点的详细信息
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({ placeId: item.place_id }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            let location = results[0].geometry.location;
            that.maps.setCenter(location);
            that.searchLocation = [location];
          }
        });
      } else {
        that.searchLocation = [item.point]; //搜索选中的定位信息
        if (that.editRegion == 1) {
          that.map.setViewport([item.point]);
        } else {
          that.map.clearOverlays();
          that.$store.state.efRemark = "2";
          that.getAdministrativeArea(item);
          that.mapOndblclick();
          EventBus.$emit("emptyChoice");
        }
      }
    },
    mapOndblclick() {
      this.map.ondblclick = () => {
        if (this.editRegion == 2) {
          // console.log('用户已双击')
          this.getEnclosure();
        } else {
          this.establish();
        }
        this.startTime();
        this.map.ondblclick = null;
      };
    },
    getAdministrativeArea(row) {
      var that = this;
      this.map.clearOverlays();
      var bdary = new BMap.Boundary();
      bdary.get(row.title, function (rs) {
        console.log(rs);
        //获取行政区域
        var count = rs.boundaries.length; //行政区域的点有多少个
        if (count > 0) {
          let list = [];
          let list1 = [];
          let pointLists;
          that.overlays = { list: that.pointList };
          for (var i = 0; i < count; i++) {
            list.push(rs.boundaries[i].replace(/;/g, "|"));
            var ply = new BMap.Polygon(rs.boundaries[i], {
              strokeStyle: "dashed",
              strokeWeight: 2,
              strokeColor: "#ff3c00",
              fillColor: "rgba(255,60,0,0.3)",
              strokeOpacity: 1,
            }); //建立多边形覆盖物
            ply.id = 5;
            that.map.addOverlay(ply); //添加覆盖物
            // that.map.setViewport(ply.getPath()); //调整视野
          }
          pointLists = rs.boundaries.join(";");
          pointLists = pointLists.split(";");
          pointLists.map((item) => {
            var pt = item.split(",");
            var pts = new BMap.Point(pt[0], pt[1]);
            list1.push(pts);
          });
          that.pointList = list.join(";");
          that.overlays = { list: list.join(";") };
          that.setZoom(list1);
        } else {
          that.map.setViewport(that.searchLocation);
        }
      });
    },
    getEnclosure() {
      //更新区域群组
      let that = this;
      let array = this.overlays.Ro;
      let pointList = "";
      if (array) {
        // this.coordinateCalculationArea(array);
        that.pointList = "";
        for (var i = 0; i < array.length; i++) {
          var lat = array[i].lat;
          var lng = array[i].lng;
          if (pointList == null || pointList == "") {
            pointList = lng + "," + lat;
          } else {
            pointList = pointList + "|" + lng + "," + lat;
          }
        }
        /*经纬度*/
        that.pointList = pointList;
      }
      let parmas = {
        regionType: that.$store.state.efRemark, //类型 1自定义 2区域
        pointList: that.pointList, //经纬度数据
      };
      if (that.$store.state.efId > 0) {
        parmas.regionId = parseInt(that.$store.state.efId); //围栏id
      }
      axios
        .post(
          `${regionUrlapi}-${that.$store.state.userState.server}/extregion/changePointList`,
          qs.stringify(parmas),
          {
            headers: beasconfig.tokenSl,
          }
        )
        .then((res) => {
          if (res.data.code == 200) {
            // if(that.$store.state.efRemark == 1){    //多边形会开启双击放大
            //   that.baiduMap();    //解决双击地图自动放大
            // }
            that.clearOverwrites();
            that.emptyData();
            that.$MessageSuccess(that.$t("message.message.update_successfully"));
            that.getDrawingManager();
            setTimeout(() => {
              that.switchOnlineState(that.type);
            }, 300);
          } else {
            that.$MessageWarning(res.data.message);
          }
        });
    },
    establish() {
      //创建
      let that = this;
      that.isBool = false;
      that.isShowMouse = "none";
      // that.baiduMap();  //解决双击地图自动放大
      that.$nextTick(() => {
        that.isBool = true;
        setTimeout(() => {
          that.$refs.addGroup.openPg();
        }, 300);
      });
    },
    startTime() {
      //30s刷新地图
      //开始计时
      let that = this;
      that.clearTimer();
      if (that.editRegion != 1) {
        return;
      }
      function timerTow() {
        return setTimeout(() => {
          if (that.editRegion == 1) {
            that.preventFrequentlyRefresh();
          }
        }, 30000);
      }
      that.temporaryTime = timerTow();
    },
    clearTimer() {
      // 清除定时器
      if (this.temporaryTime != null) {
        // clearInterval(this.temporaryTime);
        clearTimeout(this.temporaryTime);
        // this.timeNum = 0;
        this.temporaryTime = null;
      }
    },
    changeMap(type, show) {
      //改变地图状态
      this.closeGraphical();
      if (type == 1) {
        //显示
        this.isShow = show;
        this.removeMarkers();
        if (this.editRegion == 1) {
          this.map.clearOverlays(); //清楚覆盖物
        }
        if (show) {
          this.$store.state.recordLable = []; //如何点击从false为true就把记录删除Lable的值给回到最初。
        }
        if (
          this.$store.state.efId > 0 &&
          this.$store.state.selectedInfo.length > 0 &&
          this.$store.state.selectedInfo[0].children &&
          this.$store.state.selectedInfo[0].children.length <= 0
        ) {
          this.getRegionDetails();
        } else {
          this.switchOnlineState(this.type);
        }
      } else if (type == 2) {
        //切换地图类型
        show
          ? this.map.setMapType(BMAP_NORMAL_MAP)
          : this.map.setMapType(BMAP_HYBRID_MAP);
        this.BmapType = show ? BMAP_NORMAL_MAP : BMAP_HYBRID_MAP;
        let setMaxZoom = show ? 20 : 19; //卫星地图只能缩放到19
        this.map.setMaxZoom(setMaxZoom); //设置地图缩放的最大值
        // 谷歌
        this.maps.setMapTypeId(
          show ? google.maps.MapTypeId.ROADMAP : google.maps.MapTypeId.SATELLITE
        ); //正常  卫星
      } else if (type == 3) {
        if (show) {
          this.traffic = new BMap.TrafficLayer(); //  *  创建交通流量图层实例`
          this.map.addTileLayer(this.traffic);
        } else {
          this.map.removeTileLayer(this.traffic);
          this.traffic = "";
        }
        if (this.mapTrafficLayer.getMap() && !show) {
          // 如果路况图层已经添加到地图上，则移除它
          this.mapTrafficLayer.setMap(null);
        } else {
          // 如果路况图层还没有添加到地图上，则添加它
          this.mapTrafficLayer.setMap(this.maps);
        }
      } else if (type == 4) {
        if (show) {
          //放大
          this.map.zoomIn();
          // this.map.zoomTo(this.map.getZoom() + 1);
          this.maps.setZoom(this.maps.zoom + 1);
        } else {
          //缩小
          this.map.zoomOut();
          this.maps.setZoom(this.maps.zoom - 1);
          // this.map.zoomTo(this.map.getZoom() - 1);
        }
      } else if (type == 5) {
        //测距
        if (this.mapType == "Wgs84") {
          // 创建测距工具
          this.cleanupDraw(); //先清除
          this.googleDistanceTool = new google.maps.Polyline({
            strokeColor: "#FF0000", // 线条颜色
            strokeOpacity: 1.0, // 线条透明度
            strokeWeight: 2, // 线条宽度
            map: this.maps, // 绑定地图实例
          });
          this.maps.setOptions({ draggableCursor: `crosshair` }); //设置谷歌地图鼠标图标
          this.distinguishType = 5;
          // this.drawShape();
        } else {
          this.distanceTool.open();
        }
      } else if (type == 6) {
        //标注
        if (this.mapType == "baidu") {
          this.drawingManager.close();
          this.drawingManager.open(); //开启绘制
          this.drawingManager.setDrawingMode(BMAP_DRAWING_MARKER);
        } else {
          this.cleanupDraw(); //先清除
          this.distinguishType = 4;
          this.drawShape();
        }
      }
    },
    cleanupDraw() {
      //清除测距功能
      if (this.rangeArr.length > 0) {
        //如果是绘制的时候点直接清除
        this.rangeCompleto.forEach((item) => item.setMap(null));
        this.googleDistanceTool.setMap(null);
        this.rangeArr = [];
        this.distinguishType = 0;
        this.maps.setOptions({ draggableCursor: `auto` }); //设置谷歌地图鼠标图标
      }
    },
    switchOnlineState(type, name) {
      if (name) {
        this.$store.state.recordLable = [];
      }
      this.clearTimer();
      //切换在线状态
      this.coordinate = []; //每次更新数据都清空
      let mapMarkerIndex = this.$store.state.mapMarkerIndex;
      let arr = [];
      let userArray;
      let uid;
      let array =
        this.$store.state.selectedInfo.length > 0
          ? this.$store.state.selectedInfo
          : this.$store.state.groupUserData; //初始拿不到值的时候拿缓存值。
      if (mapMarkerIndex == 1 && this.$store.state.selectedInfo.length > 0) {
        //首先判断是否有点击名称
        let Gid = this.$store.state.selectedInfo[0].Gid
          ? this.$store.state.selectedInfo[0].Gid
          : this.$store.state.selectedInfo[0].gid;
        array = [this.$store.state.groupUserData.find((item) => item.Gid == Gid)]; //选中群组
        if (!this.$store.state.selectedInfo[0].children) {
          //选中当个用户的时候
          array = [
            array[0].children.find(
              (item) => this.$store.state.selectedInfo[0].id == item.id
            ),
          ];
        }
      }
      if (array.length > 0 && array[0].children) {
        array.forEach((item) => {
          // console.log(item);
          arr.push(...item.children);
        });
      } else {
        arr = array;
      }
      if (type == 0 || this.recordType == 1 || !array[0].children) {
        //!array[0].children 判断点击的是群组还是用户
        //全部
        userArray = this.deweight(arr);
        uid = userArray.map((item) => item.id);
      } else if (type == 1) {
        //在线人数
        userArray = this.deweight(arr).filter((item) => item.online == 3);
        uid = userArray.map((item) => item.id);
      } else {
        //不在线人
        userArray = this.deweight(arr).filter((item) => item.online != 3);
        uid = userArray.map((item) => item.id);
      }
      // }
      this.type = type;
      this.removeMarkers();
      this.pointPosition = [];
      this.markers = [];
      this.coordinate = this.deweight(arr).filter((item) => item.online == 3);
      this.getMarkList();
      if (userArray.length > 0 || mapMarkerIndex === 0) {
        // this.eliminateCheck(1);
        if (mapMarkerIndex === 0) {
          this.getSosMarkerLocation();
        } else {
          this.getAnchorPoint(uid, userArray);
        }
      } else if (
        this.$store.state.efId > 0 &&
        this.$store.state.selectedInfo[0].children &&
        this.$store.state.selectedInfo[0].children.length > 0
      ) {
        this.getRegionDetails();
      }
      this.startTime();
    },
    /*用户id获取定位点*/
    getAnchorPoint(uid, users) {
      var that = this;
      let uids = uid.join(",");
      var params = {
        Uids: uids, //再线成员用户id
        CorrdinateType: "Wgs84",
      };
      getQueryUserIcon({
        orgId: this.$store.state.userInfo.org_id,
        userIds: uids,
      }).then((row) => {
        let imgs = row.data.data;
        axios
          .post(
            gpsUrlapi + "/shanli/gps/api/locations/LastLocation?",
            qs.stringify(params),
            {
              headers: beasconfig.tokenSl,
            }
          )
          .then((res) => {
            // console.log("定位返回", res);
            if (res.data.Data.length > 0) {
              var locationData = res.data.Data;
              for (var i = 0; i < users.length; i++) {
                for (var j = 0; j < locationData.length; j++) {
                  if (users[i].id == locationData[j].Uid) {
                    // console.log("id----1111", users[i].id);
                    users[i].pot = locationData[j];
                  }
                }
              }
              //东西是经度  南北是纬度
              var gps = {}; //找出相同的定位点
              users.forEach(function (item) {
                let n = imgs.find((m) => m.user_id == item.id);
                if (item.pot) {
                  if (n) {
                    item.imgTabId = n.type;
                    item.iId = n.iId;
                    item.img = n.img;
                  }
                  var pint = `${item.pot.Lng}${item.pot.Lat}`;
                  if (gps[pint]) {
                    //判断是否有这个数组
                    gps[pint].push(item);
                  } else {
                    gps[pint] = [item];
                  }
                  if (that.type == 1) {
                    that.coordinate.push(item);
                  }
                  var pt;
                  if (that.mapType == "Wgs84") {
                    pt = item;
                  } else {
                    pt = new BMap.Point(item.pot.Lng, item.pot.Lat);
                  }
                  that.pointPosition.push(pt);
                  item.pot.Rtime = that
                    .$moment(Number(item.pot.Rtimestamp))
                    .format("YYYY-MM-DD HH:mm:ss");
                  item.isExceed =
                    item.online == 3 ? that.InspectionTime(item.pot.Rtime) : false;
                }
              });
              for (const key in gps) {
                that.setMarker(gps[key]);
              }
              if (
                that.$store.state.efId > 0 &&
                that.$store.state.selectedInfo.length > 0
              ) {
                that.getRegionDetails();
              } else {
                that.setZoom(that.pointPosition, that.theFirstTime);
              }
              // if(that.gooleCover.length > 0){  //谷歌地图点聚合
              //   var mcOptions = {gridSize: 2, maxZoom: 17};
              //   this.googleMarkerClusterer =  new MarkerClusterer({ markers:that.gooleCover, map:that.maps,mcOptions });
              // }
            } else {
              if (that.recordType == 1) {
                that.nullMessage();
              }
            }
          });
      });
    },
    nullMessage: _throttle(function () {
      //没数据的时候提示
      this.recordType = 0;
      this.$MessageWarning(this.$t("message.noData"));
    }, 500),
    preventFrequentlyRefresh: _throttle(function () {
      //阻止频繁刷新
      this.switchOnlineState(this.type);
    }, 1000),
    eliminateCheck(type) {
      //清除绘制选中
      if (this.distinguishType != 0) {
        if (type != 1) {
          this.clearOverwrites();
        }
        this.drawingManager.close(); //刷新就重置绘图工具
        this.$refs.mapcustomfence.selectState.isCustom = false;
        this.isCustom = true;
        this.distinguishType = 0;
      }
    },
    deweight(arr) {
      //数组去重
      let de_arr = [];
      arr.forEach((i) => {
        let isTrue = de_arr.every((j) => j.id != i.id);
        isTrue ? de_arr.push(i) : "";
      });
      return de_arr;
    },
    oneselfPosition(type) {
      //当前位置
      let that = this;
      const geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function (r) {
        // console.log(r);
        that.oneself = {
          lat: r.point.lat,
          lng: r.point.lng,
        };
        if (type == 1) {
          that.map.setViewport([
            {
              lat: r.point.lat,
              lng: r.point.lng,
            },
          ]);
        }
      });
    },
    /*百度地图*/
    baiduMap() {
      var that = this;
      var map = new BMap.Map("allmap", {
        enableMapClick: false,
        restrictCenter: false,
      });
      map.centerAndZoom("北京", 10);
      map.enableScrollWheelZoom(true);
      map.disableDoubleClickZoom(true); // 禁用双击放大
      map.setDefaultCursor("inherit"); //修改鼠标样式
      // that.oneselfPosition();   //当前位置
      /*比例尺*/
      // map.addControl(new BMap.NavigationControl({
      //     type: BMAP_NAVIGATION_CONTROL_LARGE,
      //     anchor: BMAP_ANCHOR_BOTTOM_LEFT,
      //     offset: new BMap.Size(20, 50)
      // }));
      // map.addControl(new BMap.MapTypeControl({
      //     mapTypes:[
      //         BMAP_NORMAL_MAP,     //百度地图
      //         BMAP_HYBRID_MAP     //卫星地图
      //     ]
      // }))
      // console.log(that.BmapType)
      if (that.traffic && that.traffic != "") {
        that.traffic = new BMap.TrafficLayer(); //  *  创建交通流量图层实例`
        map.addTileLayer(that.traffic);
      }
      map.setMapType(that.BmapType);
      // this.distanceTool.enable()
      // 测距功能
      that.distanceTool = new BMapLib.DistanceTool(map, { lineStroke: 2 });
      //监听测距尺关闭的时候触发的事件
      // that.distanceTool.addEventListener("removepolyline", function(e) {
      //   console.log("removepolyline");
      //   // console.log(e);
      // });
      // //监听测距尺鼠标点击添加点的时候触发的事件
      // that.distanceTool.addEventListener("addpoint", function(e) {
      //   console.log("addpoint");
      // });
      //监听测距尺鼠标右击测距尺添加完成的时候触发的事件
      // that.distanceTool.addEventListener("drawend", function(e) {
      //     that.distanceTool.close()
      // });
      map.addEventListener("mousemove", function (e) {
        if (e.cb && e.cb.id == 5 && that.editRegion != 1) {
          that.map.setDraggingCursor("inherit"); //修改鼠标样式
          that.isShowMouse = "block";
          that.offsetLeft = e.pixel.x - 15;
          that.offsetTop = e.pixel.y - 30;
        } else {
          that.isShowMouse = "none";
        }
      });
      that.markerClusterer = new BMapLib.MarkerClusterer(map, {
        markers: null,
        minClusterSize: 1,
      });
      that.map = map;
      that.getDrawingManager();
    },
    getDrawingManager() {
      //实例化鼠标绘制工具
      let that = this;
      let strokeColor = "";
      let fillColor = "";
      if (that.editRegion == 1) {
        strokeColor = "#0064fc";
        fillColor = "rgba(22,111,245,0.2)";
      } else {
        strokeColor = "#ff3c00";
        fillColor = "rgba(255,60,0,0.3)";
      }
      var styleOptions = {
        strokeColor: strokeColor, //边线颜色。
        fillColor: fillColor, //填充颜色。当参数为空时，圆形将没有填充效果。
        strokeWeight: 2, //边线的宽度，以像素为单位。
        strokeOpacity: 0.8, //边线透明度，取值范围0 - 1。
        fillOpacity: 0.6, //填充的透明度，取值范围0 - 1。
        strokeStyle: "dashed", //边线的样式，solid或dashed。
      };
      var drawingManager = new BMapLib.DrawingManager(that.map, {
        isOpen: false, //是否开启绘制模式
        enableDrawingTool: false, //是否显示工具栏
        drawingToolOptions: {
          anchor: BMAP_ANCHOR_TOP_RIGHT, //位置
          offset: new BMap.Size(5, 5), //偏离值
        },
        circleOptions: styleOptions, //圆的样式
        polylineOptions: styleOptions, //线的样式
        polygonOptions: styleOptions, //多边形的样式
        rectangleOptions: styleOptions, //矩形的样式
      });
      drawingManager.addEventListener("overlaycomplete", function (e) {
        console.log("绘制完成返回", e);
        var allOverlay = that.map.getOverlays();
        // console.log('allOverlay', allOverlay)
        if (e.overlay.Ro && e.overlay.Ro.length > 0 && e.overlay.Ro.length < 4) {
          that.mapIconDisplay = true;
          that.clearOverwrites();
          if (that.distinguishType == 1) {
            that.hint(that.$t("message.message.RedefineCustomization"));
            setTimeout(() => {
              that.drawPolylines();
            }, 200);
          } else if (that.distinguishType == 2) {
            that.drawCircle();
          } else if (that.distinguishType == 3) {
            that.drawRectangle();
          }
        } else {
          if (e.drawingMode == "marker") {
            that.mark = e.overlay.point;
            that.setMarkImg(that.mark);
            that.dialogModifyMark = true;
            drawingManager.close(); //停止绘制
            that.map.removeOverlay(e.overlay);
            return;
          }
          that.msgTipData.tipstext = that.$t("message.Areyousuretosavethefence");
          that.dialogVisible = true;
          that.hintDivShow = false;
          drawingManager.close(); //停止绘制
          // drawingManager.disableCalculate(); //停止面积计算
          that.overlays = e.overlay;
          // that.showArea = '1';
          that.$refs.mapcustomfence.selectState.isCustom = false;
          that.isCustom = true;
          if (that.editRegion == 1) {
            that.shapeType(e.overlay);
            that.startTime();
          } else {
            that.clearOverwrites();
            that.polygonMap();
            that.$store.state.efRemark = "1";
          }
          that.distinguishType = 0;
        }
      });
      that.drawingManager = drawingManager;
    },
    polygonMap() {
      //绘制地图
      let that = this;
      var list = [];
      //删除绘制图形
      // var pointLists = this.pointList.split("|");
      that.overlays.Ro.map((item) => {
        var pts = new BMap.Point(item.lng, item.lat);
        list.push(pts);
      });
      var polygon = new BMap.Polygon(list, {
        strokeStyle: "dashed",
        strokeWeight: 2,
        strokeColor: "#ff3c00",
        fillColor: "rgba(255,60,0,0.3)",
        strokeOpacity: 1,
      });
      that.map.addOverlay(polygon);
      polygon.addEventListener("lineupdate", (e) => {
        // console.log("e", e);
        that.overlays = e.target;
      });
      polygon.addEventListener("mousemove", function (e) {
        that.isShowMouse = "block";
        that.offsetLeft = e.pixel.x - 15;
        that.offsetTop = e.pixel.y - 30;
      });
      polygon.addEventListener("mouseout", function (e) {
        that.isShowMouse = "none";
      });
      that.polygon = polygon;
      that.polygon.enableEditing();
      that.mapOndblclick();
    },
    InspectionTime(time) {
      //验证时间
      let Rtime = this.$moment(time).valueOf(); //定位上报时间
      let Dtime = this.$moment(new Date()).valueOf(); //当前时间
      let Ztime = 60 * 1000 * 5; //5分钟
      let Mtime = Dtime - Rtime; //当前时间减去定位上报时间
      let isExceed = Mtime >= Ztime; //判断是否超过五分钟
      return isExceed;
    },
    selectedBall() {
      //选择半球
      this.switchOnlineState(this.type);
    },
    /*绘制入口*/
    openDrawing() {
      this.drawingManager.close(); //停止绘制
      this.clearOverwrites();
      this.$refs.mapcustomfence.selectState.isCustom = false;
      this.isCustom = true;
    },
    drawShape() {
      //绘制地图形状
      let that = this;
      if (that.distinguishType != 4) {
        that.isCustom = false;
        that.mapIconDisplay = false;
        that.clearTimer();
      }
      let color;
      let fillColor;
      let strokeWeight = 1;
      if (that.editRegion == 1) {
        color = "#0064fc";
        fillColor = "rgba(22,111,245,0.2)";
      } else {
        color = "#ff3c00";
        fillColor = "rgba(255,60,0,0.3)";
      }
      let index = that.distinguishType - 1;
      if (this.mapType == "Wgs84") {
        let drawingOptions = [
          {
            polygonOptions: "", //多边形
          },
          {
            circleOptions: "", //圆形
          },
          {
            rectangleOptions: "",
          },
          {
            markerOptions: "",
          },
          {
            polylineOptions: "",
          },
        ];
        let modeArr = [
          google.maps.drawing.OverlayType.POLYGON,
          google.maps.drawing.OverlayType.CIRCLE,
          google.maps.drawing.OverlayType.RECTANGLE,
          google.maps.drawing.OverlayType.MARKER,
          google.maps.drawing.OverlayType.POLYLINE,
        ];
        let opions = drawingOptions[index];
        let key = Object.keys(opions)[0];
        if (index == 4) {
          color = "#FF0000";
          fillColor = "#FF0000";
          strokeWeight = 2;
        }
        opions[key] = {
          strokeColor: color,
          fillColor: fillColor,
          // fillOpacity: "var(--main-color)",
          strokeWeight: strokeWeight,
          clickable: false,
          editable: false,
          strokeStyle: "dashed",
          zIndex: 1,
          icon: require("@/assets/map/mark.png"), // 初始图标路径
        };

        that.googleDraw = new google.maps.drawing.DrawingManager({
          drawingMode: modeArr[index],
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: ["polygon"],
          },
          ...opions,
        });
        google.maps.event.addListener(
          that.googleDraw,
          "polylinecomplete",
          function (polyline) {
            //测距功能
            var pointxy = polyline.getPath();
            var triangleCoords = [];
            for (var i = 0; i < pointxy.length; i++) {
              var pointstemp = new google.maps.LatLng(
                pointxy.getAt(i).lat(),
                pointxy.getAt(i).lng()
              );
              triangleCoords.push(pointstemp);
            }
            new google.maps.Polyline({
              path: triangleCoords,
              map: that.maps,
              strokeColor: "#FF0000",
              strokeOpacity: 1.0,
              strokeWeight: 2,
            });
            //直接调用API接口来计算几条线段的长度和 单位米
            var distanceValue = google.maps.geometry.spherical.computeLength(
              triangleCoords
            );
            distanceValue = `<div class="ranging">${this.$t("message.totallength")}：${(
              distanceValue / 1000
            ).toFixed(2)}Km</div>`;
            this.googleMarkerLabel({
              position: triangleCoords[triangleCoords.length - 1],
              html: distanceValue,
            });
          }
        );
        google.maps.event.addListener(
          that.googleDraw,
          "markercomplete",
          function (event) {
            //自定义标注。
            var position = event.position;
            var latLng = { lat: position.lat(), lng: position.lng() };
            that.mark = latLng;
            that.setMarkImg(that.mark);
            that.dialogModifyMark = true;
          }
        );
        google.maps.event.addListener(
          that.googleDraw,
          "circlecomplete",
          function (circle) {
            //用户刚刚绘制的圆形。
            var radius = circle.getRadius();
            let userList = [];
            // let bounds = circle.getBounds();
            let array = that.deweight(that.coordinate);
            that.gooleCover.forEach((marker) => {
              let mark = marker.position;
              let bool = google.maps.geometry.spherical.computeDistanceBetween(
                mark,
                circle.getCenter()
              ); //判断当前用户是否被框选
              let row = array.find((item) => item.id == marker.id);
              if (bool <= radius && row) {
                //判断覆盖物是否在矩形内
                userList.push(row);
              }
            });
            that.temporaryGroup(userList);
          }
        );
        google.maps.event.addListener(
          that.googleDraw,
          "polygoncomplete",
          function (event) {
            //用户刚刚绘制的多边形。
            let arr = [];
            let userList = [];
            let array = that.deweight(that.coordinate);
            event.getPath().forEach(function (latLng) {
              arr.push(new google.maps.LatLng(latLng.toString().replace(/[\(\)]/g, "")));
            });
            that.gooleCover.forEach((marker) => {
              let mark = marker.position;
              let bool = google.maps.geometry.poly.containsLocation(mark, event); //判断当前用户是否被框选
              let row = array.find((item) => item.id == marker.id);
              if (bool && row) {
                //判断覆盖物是否在矩形内
                userList.push(row);
              }
            });
            that.temporaryGroup(userList);
          }
        );

        google.maps.event.addListener(
          that.googleDraw,
          "rectanglecomplete",
          function (event) {
            //用户刚刚绘制的矩形
            var bounds = event.getBounds();
            // var start = bounds.getNorthEast();
            // var end = bounds.getSouthWest();
            // var center = bounds.getCenter();
            let userList = [];
            let array = that.deweight(that.coordinate);
            that.gooleCover.forEach((marker) => {
              let mark = marker.position;
              let bool = bounds.contains(mark); //判断是否在矩形里面
              let row = array.find((item) => item.id == marker.id);
              if (bool && row) {
                //判断覆盖物是否在矩形内
                userList.push(row);
              }
            });
            that.temporaryGroup(userList);
          }
        );

        google.maps.event.addListener(
          that.googleDraw,
          "overlaycomplete",
          function (event) {
            that.graphsDrawn = event.overlay; //绘制好的图形
            // event.overlay.setMap(null);  //设置为空就是清除绘制
            // that.googleDraw.setMap(null);   //停止绘制
            setTimeout(() => {
              that.closeGraphical();
            }, 300);
            if (that.editRegion == 1) {
              that.startTime();
            }
          }
        );

        that.googleDraw.setMap(that.maps);
      } else {
        let bdmapDrawArr = [
          BMAP_DRAWING_POLYGON,
          BMAP_DRAWING_CIRCLE,
          BMAP_DRAWING_RECTANGLE,
        ];
        that.drawingManager.open(); //开启绘制
        that.drawingManager.setDrawingMode(bdmapDrawArr[index]);
        that.distanceTool.close();
      }
    },
    closeGraphical(type) {
      //关闭图形
      if (this.editRegion != 1) {
        this.clearOverwrites();
      }
      if (type == 1) {
        this.theFirstTime = 0;
        this.onmouseout();
        this.mapIconDisplay = false;
      } else {
        this.$refs.mapcustomfence.selectState.isCustom = false;
      }
      this.isCustom = true;
      this.distinguishType = 0;
      this.drawingManager.close();
      if (this.graphsDrawn) {
        this.graphsDrawn.setMap(null); //设置为空就是清除绘制
      }
      if (this.googleDraw) {
        this.googleDraw.setMap(null); //停止绘制
      }
    },
    /*画多边形*/
    drawPolylines() {
      // if(this.editRegion != 1){
      //   this.clearOverwrites();
      // }
      this.distinguishType = 1;
      this.drawShape();
    },
    /*画圆圈*/
    drawCircle() {
      if (this.editRegion != 1) {
        this.clearOverwrites();
      }
      this.distinguishType = 2;
      this.drawShape();
    },
    /*画矩形*/
    drawRectangle() {
      if (this.editRegion != 1) {
        this.clearOverwrites();
      }
      this.distinguishType = 3;
      this.drawShape();
    },
    /*返回上一步*/
    previousStep() {
      if (this.editRegion == 1 && this.distinguishType == 0) {
        return;
      }
      this.closeGraphical(1);
      if (this.editRegion != 1) {
        this.editRegion = 1;
        this.emptyData();
        this.getDrawingManager();
      }
    },
    /*删除围栏区域*/
    deleteFenceArea() {
      if (this.editRegion == 1 && this.distinguishType == 0) {
        return;
      }
      this.closeGraphical(1);
      this.$refs.mapcustomfence.selectState.isCustom = false;
      if (this.editRegion != 1) {
        this.editRegion = 1;
        this.emptyData();
        this.getDrawingManager();
      }
      return;
      this.map.clearOverlays();
    },
    /*清除覆盖*/
    clearOverwrites() {
      var allOverlay = this.map.getOverlays();
      if (allOverlay) {
        for (var i = 0; i < allOverlay.length; i++) {
          //多边行 圆形 正方形
          let obj = allOverlay[i].toString();
          if (this.editRegion == 1) {
            if (this.distinguishType == 0) {
              if (
                (obj === "[object Polygon]" &&
                  !allOverlay[i].id &&
                  this.distinguishType == 0) ||
                (obj === "[object Circle]" &&
                  !allOverlay[i].id &&
                  this.distinguishType == 0)
              ) {
                this.map.removeOverlay(allOverlay[i]);
              } else if (
                (obj === "[object Polygon]" && allOverlay[i].id) ||
                (obj === "[object Circle]" && allOverlay[i].id)
              ) {
                this.map.removeOverlay(allOverlay[i]);
              }
            } else {
              if (
                (obj === "[object Polygon]" && !allOverlay[i].id) ||
                (obj === "[object Circle]" && !allOverlay[i].id)
              ) {
                this.map.removeOverlay(allOverlay[i]);
              }
            }
          } else {
            if (
              obj === "[object Polygon]" ||
              obj === "[object Circle]" ||
              obj === "[object Vertex]"
            ) {
              this.map.removeOverlay(allOverlay[i]);
            }
          }
        }
      }
      if (this.graphsDrawn && this.distinguishType == 0) {
        this.graphsDrawn.setMap(null);
      }
    },
    /*提示语*/
    hint(msg) {
      this.$MessageWarning(msg);
    },
    async shapeType(overlay) {
      //区分形状
      let array = this.deweight(this.coordinate);
      let selectData = []; //图形选中的值
      var bool;
      let distinguishType = this.distinguishType;
      var graphical = new GeoUtils();
      this.clearOverwrites();
      //检查类型是否正确
      await array.forEach((item) => {
        if (item["pot"]) {
          var Point = new BMap.Point(item["pot"].Lng, item["pot"].Lat);
          if (distinguishType == 1 || distinguishType == 3) {
            //多边形
            bool = graphical.constructor.isPointInPolygon(Point, overlay);
            if (bool) {
              selectData.push(item);
            }
          } else if (distinguishType == 2) {
            //圆形
            bool = graphical.constructor.isPointInCircle(Point, overlay);
            if (bool) {
              selectData.push(item);
            }
          }
        }
        // else if (distinguishType == 3) {
        //   //矩形
        //   // isPointInRect
        //   bool = graphical.constructor.isPointInRect(Point, overlay);
        //   if (bool) {
        //     selectData.push(item);
        //   }
        // }
      });
      if (array.length <= 0 || this.type == 2) {
        this.$MessageWarning(this.$t("message.message.Unabletocreatetemporarygroup"));
        return;
      }
      this.temporaryGroup(selectData);
    },
    temporaryGroup(arr) {
      //呼叫临时群组
      if (arr.length > 0 && this.editRegion == 1) {
        if (this.type != 2) {
          EventBus.$emit("temporaryGroup", arr);
        }
      } else {
        this.$MessageWarning(this.$t("message.message.Unabletocreatetemporarygroup"));
      }
    },
    focus() {
      //获取焦点
      this.$store.state.isFocusing = true;
    },
    blur() {
      //失去焦点
      this.$store.state.isFocusing = false;
    },
    qcMarker() {
      //删除指定的Marker 和 Label
      var allOverlay = this.map.getOverlays();
      if (allOverlay.length) {
        for (var i = 0; i < allOverlay.length; i++) {
          if (
            (allOverlay[i].toString() == "[object Marker]" && allOverlay[i].id) ||
            (allOverlay[i].toString() == "[object Label]" && allOverlay[i].id)
          ) {
            this.map.removeOverlay(allOverlay[i]);
          }
        }
      }
    },
    emptyData() {
      //清空data值
      if (this.editRegion != 1) {
        this.editRegion = 1;
      }
      this.search = "";
      this.recordType = 0; //记录类型
      this.pointPosition = [];
      this.$store.state.selectedInfo = [];
      this.$store.state.efId = 0;
      this.dialogTitle = this.$t("message.Createregionalgroups");
      this.offsetLeft = 0;
      this.offsetTop = 0;
      this.isShowMouse = "none";
      this.regionType = 0;
      this.overlays = {};
      this.map.ondblclick = null;
      this.$store.state.efRemark = "1";
    },
  },
  watch: {
    //监听事件
    "$store.state.groupUserData": {
      handler(newValue) {
        if (newValue.length > 0 && this.frequency == 0) {
          this.frequency = 1;
          this.preventFrequentlyRefresh();
        }
      },
      // deep: true,
      immediate: true,
    },
    "$store.state.mapMarkerIndex"() {
      this.preventFrequentlyRefresh();
    },
  },
};
</script>
<style scoped lang="less">
.box {
  overflow: hidden;
}

.baidu-api {
  width: 100%;
  height: 100%;
}

.flex {
  display: flex;
  align-items: center;
}

/* 去除百度地图版权那行字 和 百度logo */
.baidumap > .BMap_cpyCtrl {
  display: none !important;
}

// .BMap_noprint {
//   display: block !important;
// }

.anchorBL {
  display: none;
}

.search {
  width: 455px;
  position: absolute;
  top: 10px;
  left: 10px;
  // width: 418px;
  height: 40px;
  overflow: hidden;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px;

  &_x {
    width: 50px;
    height: 100%;
    line-height: 37px;
    border-left: 1px solid #e2e2e2;
    color: #ccc;
    font-size: 30px;
    font-weight: 400;
  }

  &_input {
    flex: 1;
    height: 100%;
    background-color: #fff;
    color: #696969 !important;

    /deep/.el-input__inner {
      width: calc(100% - 42px);
      // width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #fff;
      color: #696969 !important;
      border: none;
      border-radius: 0px;
      padding: 0px;
    }
  }

  &_but {
    width: 58px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: var(--main-color);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 17px;
      height: 17px;
    }
  }

  // &_title {
  //   width: 100px;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  // }
}

.search_title {
  // width: 00px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.flex_align {
  display: flex;
  align-items: center;
}

#mouseOffset {
  color: red;
  font-size: 13px;
  background-color: rgb(255, 255, 255);
  padding: 0px 5px;
  border: 1px solid rgb(0, 0, 0);
}

/deep/.baidu_lAbel_triangle {
  width: 0px;
  border-style: solid;
  border-color: transparent;
  border-width: 10px 10px 8px 10px;
  border-top-color: var(--main-color);
  opacity: 1;
  margin: auto;
  position: absolute;
  left: 45%;
  bottom: -17px;
  z-index: 3;
}
</style>
